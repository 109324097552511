import React from "react"
import i18next from '../i18n/config';

import ImgOurCoffeeBack from "../img/D_kawa_ziarnista_18/iko_powrot_nasze_kawy.png";
import ImgCoffeeBeansBack from "../img/D_kawa_ziarnista_18/iko_mielona.png";
import ImgGroundCoffeeBack from "../img/E_kawa_mielona_19/iko_ziarnista.png";
import ImgRoastingIco from "../img/C_kawa_rozpuszczalna_8/iko_palenie.png";
import ImgGrindingIco from "../img/C_kawa_rozpuszczalna_8/iko_mielenie.png";
import ImgWaterIco from "../img/C_kawa_rozpuszczalna_8/iko_woda.png";
import ImgSprayIco from "../img/C_kawa_rozpuszczalna_8/iko_suszenie.png";
import ImgFinishIco from "../img/C_kawa_rozpuszczalna_8/iko_produkt.png";
import ImgFreezeIco from "../img/C_kawa_rozpuszczalna_8/freeze_dried.png";
import ImgPowderedIco from "../img/C_kawa_rozpuszczalna_8/powered.png";
import ImgGranulatedIco from "../img/C_kawa_rozpuszczalna_8/granulated.png";
import ImgMicrogrindIco from "../img/C_kawa_rozpuszczalna_8/microgrind.png";
import ImgCremaIco from "../img/C_kawa_rozpuszczalna_8/crema.png";
// import ImgGranulatedIco from "../img/C_kawa_rozpuszczalna_8/";
import ImgFreezeCoffee from "../img/C_kawa_rozpuszczalna_8/liofilizat_sloik@0,5x.png";
import ImgFreezeCoffeeL from "../img/C_kawa_rozpuszczalna_8/liofilizat_sloik@0,75x.png";
import ImgPowderedCoffee from "../img/C_kawa_rozpuszczalna_8/proszek_sloik@0,5x.png";
import ImgPowderedCoffeeL from "../img/C_kawa_rozpuszczalna_8/proszek_sloik@0,75x.png";
import ImgGranulatedCoffee from "../img/C_kawa_rozpuszczalna_8/granulat_sloik@0,5x.png";
import ImgGranulatedCoffeeL from "../img/C_kawa_rozpuszczalna_8/granulat_sloik@0,75x.png";
import ImgMicrogrindCoffee from "../img/C_kawa_rozpuszczalna_8/microgrind_sloik@0,5x.png";
import ImgMicrogrindCoffeeL from "../img/C_kawa_rozpuszczalna_8/microgrind_sloik@0,75x.png";
import ImgCremaCoffee from "../img/C_kawa_rozpuszczalna_8/crema_sloik@0,5x.png";
import ImgCremaCoffeeL from "../img/C_kawa_rozpuszczalna_8/crema_sloik@0,75x.png";
import ImgArrowIco from "../img/C_kawa_rozpuszczalna_8/iko_strzalka.png";

import { Link, Trans, useTranslation, useI18next }
from 'gatsby-plugin-react-i18next';


export default function Header(props) {



    return (
            <React.Fragment>
                <section id="instantcoffee" class="instantcoffee firstSection">
                    <h1>{i18next.t('InstantCoffeeH1')}</h1>
                    <p>{i18next.t('InstantCoffeeP')}</p>

                    <div class="icons">
                        <div class="icon">
                            <div><img loading="lazy" src={ImgRoastingIco} alt="Instanta Coffee Roasting Icon" /></div>
                            <p>{i18next.t('InstantCoffeeRoastic')}</p>
                        </div>
                        <div class="ArrowIco"><img loading="lazy" src={ImgArrowIco} alt="Instanta Coffee Arrow Icon" /></div>
                        <div class="icon">
                            <div><img loading="lazy" src={ImgGrindingIco} alt="Instanta Coffee Grinding Icon" /></div>
                            <p>{i18next.t('InstantCoffeeGrinding')}</p>
                        </div>
                        <div class="ArrowIco"><img loading="lazy" src={ImgArrowIco} alt="Instanta Coffee Arrow Icon" /></div>
                        <div class="icon">
                            <div><img loading="lazy" src={ImgWaterIco} alt="Instanta Coffee Water Icon" /></div>
                            <p>{i18next.t('InstantCoffeeWater')}</p>
                        </div>
                        <div class="ArrowIco"><img loading="lazy" src={ImgArrowIco} alt="Instanta Coffee Arrow Icon" /></div>
                        <div class="icon">
                            <div><img loading="lazy" src={ImgSprayIco} alt="Instanta Coffee Spray Icon" /></div>
                            <p>{i18next.t('InstantCoffeeSpray')}</p>
                        </div>
                        <div class="ArrowIco"><img loading="lazy" src={ImgArrowIco} alt="Instanta Coffee Arrow Icon" /></div>
                        <div class="icon">
                            <div><img loading="lazy" src={ImgFinishIco} alt="Instanta Coffee Finish Product Icon" /></div>
                            <p>{i18next.t('InstantCoffeeFinish')}</p>
                        </div>
                    </div>

                    <div class="features">
                        <a>
                            <div class="feature" onClick={() => document.scrolltosection("freeze-dried")} >
                                <div><img loading="lazy" src={ImgFreezeIco} alt="Instanta Coffee Freeze-dried Icon" /></div>
                                <p>{i18next.t('InstantCoffeeFreezeIco')}</p>
                            </div>
                        </a>
                        <a>
                            <div class="feature" onClick={() => document.scrolltosection("powdered")} >
                                <div><img loading="lazy" src={ImgPowderedIco} alt="Instanta Coffee Powdered Icon" /></div>
                                <p>{i18next.t('InstantCoffeePowderedIco')}</p>
                            </div>
                        </a>
                        <a>
                            <div class="feature" onClick={() => document.scrolltosection("granulated")} >
                                <div><img loading="lazy" src={ImgGranulatedIco} alt="Instanta Coffee Granulated Icon" /></div>
                                <p>{i18next.t('InstantCoffeeGranulatedIco')}</p>
                            </div>
                        </a>
                        <a>
                            <div class="feature" onClick={() => document.scrolltosection("microgrind")} >
                                <div><img loading="lazy" src={ImgMicrogrindIco} alt="Instanta Coffee Microgrind Icon" /></div>
                                <p>{i18next.t('InstantCoffeeMicrogrindIco')}</p>
                            </div>
                        </a>
                        <a>
                            <div class="feature" onClick={() => document.scrolltosection("crema")} >
                                <div><img loading="lazy" src={ImgCremaIco} alt="Instanta Coffee Crema Icon" /></div>
                                <p>{i18next.t('InstantCoffeeCremaIco')}</p>
                            </div>
                        </a>
                    </div>

                    <aside class="mininavi">
                        <div>
                            <Link to={'/OurCoffees/'}>
                            <img loading="lazy" src={ImgOurCoffeeBack} alt="Instanta Coffee Back Icon" />
                            <p>{i18next.t('CoffeeBeansMiniMenu1')}</p>
                            </Link>
                        </div>
                        <div>
                            <Link to={'/OurCoffees/GroundCoffee'}>
                            <img loading="lazy" src={ImgCoffeeBeansBack} alt="Instant Ground Coffee Icon" />
                            <p>{i18next.t('InstantCoffeeMiniMenu2')}</p>
                            </Link>
                        </div>
                        <div>
                            <Link to={'/OurCoffees/CoffeeBeans'}>
                            <img loading="lazy" src={ImgGroundCoffeeBack} alt="Instanta Coffee Beans Icon" />
                            <p>{i18next.t('GroundCoffeeMiniMenu3')}</p>
                            </Link>
                        </div>
                    </aside>
                </section>


                <section id="freeze-dried" class="freeze-dried main">
                    <div class="image">
                        <picture>
                            <source media="(min-width: 1024px)" srcset="/img/OurCoffees/liofilizat_sloik@0,75x.png" />
                            <img loading="lazy" src="/img/OurCoffees/liofilizat_sloik@0,5x.png" alt="Instanta Coffee Freeze-Dried" />
                        </picture>


                    </div>








                    <div class="text">
                        <h2>{i18next.t('InstantCoffeeFreezeH2')}</h2>
                        <p>{i18next.t('InstantCoffeeFreezeP')}</p>
                        <div>
                            <div class="links">
                                <div class="link">
                                    <Link to={'/OurPackaging/Packaging'}>
                                    <p>{i18next.t('CoffeeBeansLink1')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/Collaboration/'}>
                                    <p>{i18next.t('CoffeeBeansLink2')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/Quality/'}>
                                    <p>{i18next.t('CoffeeBeansLink3')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/OurPackaging/Design'}>
                                    <p>{i18next.t('CoffeeBeansLink4')}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="powdered" class="powdered main">
                    <div class="image">
                        <picture>
                            <source media="(min-width: 1024px)" srcset="/img/OurCoffees/proszek_sloik@0,75x.png" />
                            <img loading="lazy" src="/img/OurCoffees/proszek_sloik@0,5x.png" alt="Instanta Coffee Powdered" />
                        </picture>

                    </div>
                    <div class="text">
                        <h2>{i18next.t('InstantCoffeePowderedH2')}</h2>
                        <p>{i18next.t('InstantCoffeePowderedP')}</p>
                        <div>
                            <div class="links">
                                <div class="link">
                                    <Link to={'/OurPackaging/Packaging'}>
                                    <p>{i18next.t('CoffeeBeansLink1')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/Collaboration/'}>
                                    <p>{i18next.t('CoffeeBeansLink2')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/Quality/'}>
                                    <p>{i18next.t('CoffeeBeansLink3')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/OurPackaging/Design'}>
                                    <p>{i18next.t('CoffeeBeansLink4')}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="granulated" class="granulated main">
                    <div class="image">

                        <picture>
                            <source media="(min-width: 1024px)" srcset="/img/OurCoffees/granulat_sloik@0,75x.png" />
                            <img loading="lazy" src="/img/OurCoffees/granulat_sloik@0,5x.png" alt="Instanta Coffee Granulated" />
                        </picture>


                    </div>
                    <div class="text">
                        <h2>{i18next.t('InstantCoffeeGranulatedH2')}</h2>
                        <p>{i18next.t('InstantCoffeeGranulatedP')}</p>
                        <div>
                            <div class="links">
                                <div class="link">
                                    <Link to={'/OurPackaging/Packaging'}>
                                    <p>{i18next.t('CoffeeBeansLink1')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/Collaboration/'}>
                                    <p>{i18next.t('CoffeeBeansLink2')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/Quality/'}>
                                    <p>{i18next.t('CoffeeBeansLink3')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/OurPackaging/Design'}>
                                    <p>{i18next.t('CoffeeBeansLink4')}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="microgrind" class="microgrind main">
                    <div class="image">

                        <picture>
                            <source media="(min-width: 1024px)" srcset="/img/OurCoffees/microgrind_sloik@0,75x.png" />
                            <img loading="lazy" src="/img/OurCoffees/microgrind_sloik@0,5x.png" alt="Instanta Coffee Microgrind" />
                        </picture>


                    </div>
                    <div class="text">
                        <h2>






                            {i18next.t('InstantCoffeeMicrogrindH2')
                            }






                        </h2>
                        <p>






                            {i18next.t('InstantCoffeeMicrogrindP')
                            }






                        </p>
                        <div>
                            <div class="links">
                                <div class="link">
                                    <Link to=






                                          {'/OurPackaging/Packaging'
                                          }






                                          >
                                    <p>






                                        {i18next.t('CoffeeBeansLink1')
                                        }






                                    </p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to=






                                          {'/Collaboration/'
                                          }






                                          >
                                    <p>






                                        {i18next.t('CoffeeBeansLink2')
                                        }






                                    </p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to=






                                          {'/Quality/'
                                          }






                                          >
                                    <p>






                                        {i18next.t('CoffeeBeansLink3')
                                        }






                                    </p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to=






                                          {'/OurPackaging/Design'
                                          }






                                          >
                                    <p>






                                        {i18next.t('CoffeeBeansLink4')
                                        }






                                    </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="crema" class="crema main">
                    <div class="image">
                        <picture>
                            <source media="(min-width: 1024px)" srcset="/img/OurCoffees/crema_sloik@0,75x.png" />
                            <img loading="lazy" src="/img/OurCoffees/crema_sloik@0,5x.png" alt="Instanta Coffee Crema" />
                        </picture>



                    </div>
                    <div class="text">
                        <h2>






                            {i18next.t('InstantCoffeeCremaH2')
                            }






                        </h2>
                        <p>






                            {i18next.t('InstantCoffeeCremaP')
                            }






                        </p>
                        <div>
                            <div class="links">
                                <div class="link">
                                    <Link to=






                                          {'/OurPackaging/Packaging'
                                          }






                                          >
                                    <p>






                                        {i18next.t('CoffeeBeansLink1')
                                        }






                                    </p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to=






                                          {'/Collaboration/'
                                          }






                                          >
                                    <p>






                                        {i18next.t('CoffeeBeansLink2')
                                        }






                                    </p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to=






                                          {'/Quality/'
                                          }






                                          >
                                    <p>






                                        {i18next.t('CoffeeBeansLink3')
                                        }






                                    </p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to=






                                          {'/OurPackaging/Design'
                                          }






                                          >
                                    <p>






                                        {i18next.t('CoffeeBeansLink4')
                                        }






                                    </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </React.Fragment>

            )


}
